
/** 負責呼叫 devapi 的 jasmine 以取得校務群組資料 */
export class DevAPIJasmineHelper {

    /** 取得指定班級的學生清單 */
    static async getClassStudents(dsns, classID) {
        const path = `/service/devapi_jasmine/${dsns}/student?classID=${classID}`
        const resp = await fetch(path)
        const data = await resp.json();
        return data;
    }

    /** 取得指定班級的學生清單 */
    static async getHomeroomClasses(dsns) {
        const path = `/service/devapi_jasmine/${dsns}/teacher/getHomeroomClass`
        const resp = await fetch(path)
        const data = await resp.json();
        return data;
    }
}

